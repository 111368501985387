@use '@virginmediao2/momentum-css/react/components/factory/mega-menu/utils';

.mega-menu-utils {
  color: var(--color-surface-bg);
}

.mega-menu-util {
  height: unset;
}

@media (width >= 48em) {
  .mega-menu-util-hide {
    display: none;
  }
}
