@use '@virginmediao2/momentum-css/react/components/factory/mega-menu/navigation-item';

.mega-menu-navigation-item__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mega-menu-navigation-item__content {
  z-index: calc(var(--zindex-navigation-base) + 1);
}
